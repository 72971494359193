<template>
	<div class="d-flex flex-column h-100">
        <HeaderTop/>
        <Header/>
        <Lgpd
            :config_button="false"
            :privacy_policy="{
                title: 'Política de privacidade',
                link: '/informacoes/politica-de-privacidade'
            }"
            :cookies_policy="{
                title: 'Política de cookies',
                link: '/informacoes/politica-de-cookies'
            }"
        />
        <ModalLocation id="modal-1" />
        <MenuBar class="d-block d-lg-none"/>
		<Nuxt />
        <Footer class="d-none d-lg-block"/>
	</div>
</template>

<script>
import Lgpd from '~/components/Lgpd/Lgpd.vue';
import ModalLocation from '~/components/ModalLocation.vue';
import Header from '~/components/Header';
import MenuBar from "~/components/MenuBar";
import Footer from '~/components/Footer';
import Cookies from 'js-cookie';
import HeaderTop from "~/components/HeaderTop";
import gtm_init from "~/mixins/gtm_init"
export default{
	head() {
        return {
            title: this.$wdconfig.default.title_initial,
            titleTemplate: '%s | ' + this.$wdconfig.default.title_base,
            htmlAttrs: {
                lang: 'pt-BR'
            },
            meta: [
                {hid: 'description', name: 'description', content: this.$wdconfig.default.meta.description || ''},
                {hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image'},
                {hid: 'og:title', property: 'og:title', content: this.$wdconfig.default.title_base },
                {hid: 'og:site_name', property: 'og:site_name', content: this.$wdconfig.default.name },
                {hid: 'og:description', property: 'og:description', content: this.$wdconfig.default.meta.description || ''},
                {hid: 'og:image', property: 'og:image', content: this.$host + this.$wdconfig.modules.general.social_media_image_share },
                {hid: 'og:image:width', property: 'og:image:width', content: '600' },
                {hid: 'og:image:height', property: 'og:image:height', content: '315' },
                {hid: 'og:url', property: 'og:url', content: this.$host + this.$route.fullPath },
                {hid: 'msapplication-TileColor', name: 'msapplication-TileColor', content: this.$wdconfig.modules.general.favicon_tilecolor || ''},
                {hid: 'theme-color', name: 'theme-color', content: this.$wdconfig.modules.general.favicon_theme_color || ''},
                {hid: 'google-site-verification', name: 'google-site-verification', content: this.$wdconfig.modules.domain_verification.google || ''},
                {hid: 'facebook-domain-verification', name: 'facebook-domain-verification', content: this.$wdconfig.modules.domain_verification.facebook || ''},
                {hid: 'pinterest-domain-verification', name: 'p:domain_verify', content: this.$wdconfig.modules.domain_verification.bing || ''},
                {hid: 'bing-domain-verification', name: 'msvalidate.01', content: this.$wdconfig.modules.domain_verification.bing || ''},
            ],
            link: [
                {rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: this.$host + '/favicons/apple-touch-icon.png'},
                {rel: 'icon', type: 'image/png', sizes: '32x32', href: this.$host + '/favicons/favicon-32x32.png'},
                {rel: 'icon', type: 'image/png', sizes: '16x16', href: this.$host + '/favicons/favicon-16x16.png'},
                {rel: 'icon', type: 'image/x-icon', href: this.$host + '/favicons/favicon.ico'},
                {rel: 'manifest', href: this.$host + '/favicons/site.webmanifest'},
				{rel: 'canonical', href: this.$host + this.$route.path}
            ],
            style: [
                {
                    hid: 'site-theme',
                    cssText: `:root {${this.$store.getters.cssTheme}}`,
                    type: 'text/css'
                }
            ]
        }
    },
	components: {
        HeaderTop,
        Lgpd,
        ModalLocation,
	    Header,
        MenuBar,
        Footer
	},
    mixins:[
        gtm_init
    ],
    data(){
	    return{
	        modalLocation: false
        }
    },
    mounted() {
        if (Cookies.get('modal_location') === undefined) {
            this.modalLocation = true
        }
        if (this.$route.query.cupom) {
            try {
                const params = new URLSearchParams();
                params.append('metodo', 'adicionar');
                params.append('cupom', decodeURIComponent(this.$route.query.cupom));
                this.$axios.$post(`/?p=ecommerce_cupom_desconto_api_v1`, params)
                    .then(res => {
                        if (res.msg !== 'sucesso') {
                            console.log(res)
                        }
                    })
                    .catch(e => {
                        console.error(e)
                    })
            } catch (e) {
                console.error(e)
            }
        }
        if (this.$route.query.vendedor || this.$route.query.vendedor_int) {
            try {
                const params = new URLSearchParams();
                params.append('vendedor', this.$route.query.vendedor);
                params.append('vendedor_int', this.$route.query.vendedor_int);
                this.$axios.$post(`?p=ecommerce_pedidos_api_v1&metodo=vendedor`, params).then(res => {
                    if (res.status !== 'success') {
                        console.log(res)
                    }
                }).catch(e => {
                    console.error(e)
                })
            } catch (e) {
                console.error(e)
            }
        }
    },
    async fetch() {
        await this.$store.dispatch('order/loadOrder')
    },
    fetchOnServer: false
}
</script>
