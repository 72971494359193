import Cookies from "js-cookie";
import Vue from 'vue';

Vue.mixin({
    data() {
        return {
            analyticsPermission: null,
            marketingPermission: null
        };
    },
    methods: {
        updatePermissions() {
            this.analyticsPermission = Cookies.get('lgpd_load_analytics') || null;
            this.marketingPermission = Cookies.get('lgpd_load_marketing') || null;
            if (this.analyticsPermission !== null && this.marketingPermission !== null) {
                this.setConsent('update');
            }
        },
        gtagEvent(track, event, params, counter = 10) {
            setTimeout(() => {
                if (counter <= 0) {
                    return;
                }
                counter--;
                try {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { window.dataLayer.push(arguments); }
                    gtag(track, event, params);
                } catch (e) {
                    setTimeout(() => {
                        this.gtagEvent(track, event, params, counter);
                    }, 500);
                }
            },500);
        },
        setConsent(mode) {
            const setConsentMode = mode || 'default';
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            const isAnalyticsGranted = this.analyticsPermission === 'true';
            const isMarketingGranted = this.marketingPermission === 'true';
            const consent = {
                'analytics_storage': isAnalyticsGranted ? 'granted' : 'denied',
                'ad_storage': isMarketingGranted ? 'granted' : 'denied',
                'ad_user_data': isMarketingGranted ? 'granted' : 'denied',
                'ad_personalization': isMarketingGranted ? 'granted' : 'denied',
                'personalization_storage': isAnalyticsGranted ? 'granted' : 'denied',
                'functionality_storage': 'granted',
                'security_storage': 'granted',
            };
            gtag('consent', setConsentMode, consent);
        },
    },
});
