
export default {
    data: () => ({

    }),
    methods: {

    },
    mounted() {
        if (this.$wdconfig.default.google_tag_manager) {
            this.setConsent('default');
            this.updatePermissions();
        }
        const GAID = this.$wdconfig.default.google_analytics;
        const GADSID = this.$wdconfig.default.google_ads;
        const GTAGID = GAID || GADSID || undefined;

        if (GTAGID) {
            ; (function (i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r
                    ; (i[r] =
                        i[r] ||
                        function () {
                            ; (i[r].q = i[r].q || []).push(arguments);
                        }),
                        (i[r].l = 1 * new Date())
                    ; (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(
                window,
                document,
                'script',
                'https://www.googletagmanager.com/gtag/js?id=' + GTAGID,
                'ga'
            );
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            if (GAID !== GTAGID) {
                gtag('config', GAID);
            }
            if (GAID) {
                gtag('config', GAID);
            }
            if (GADSID !== GTAGID) {
                gtag('config', GADSID);
            }
        }
        if (this.$wdconfig.default.google_tag_manager) {
            this.$gtm.init(this.$wdconfig.default.google_tag_manager);
        }

    },
}


