import fb_pixel_event from "~/mixins/fb_pixel_event";
export default {
    mixins:[
        fb_pixel_event
    ],
    data() {
        return {
            product_loading: false
        }
    },
    methods: {
        addProduct(product, list_code) {
            const params = new URLSearchParams();
            if (product.type === "4") {
                if (this.product_code <= 0) {
                    if (this.$refs['modal-variation']) {
                        this.$refs['modal-variation'].showModal(() => {
                            this.addProduct(product, list_code)
                        }, this.$wdconfig.modules.product.add_to_cart_button_text)
                    } else {
                        alert('Você precisa selecionar opções do produto!')
                    }
                    return;
                }
                params.append('code', this.product_code);
            } else {
                params.append('code', product.code);
            }
            if ('additional' in this.product) {
                for (const key in this.additional) {
                    if (this.additional[key] === 0) {
                        alert('Você precisa selecionar as opções do produto!')
                        return;
                    }
                    params.append('additionals[' + key + ']', this.additional[key]);
                }
            }
            let qty = 1
            if ('qty' in product) {
                qty = product.qty
            } else if ('qty_min_order' in product && product.qty_min_order > 0) {
                qty = product.qty_min_order
            }
            params.append('qty', qty);
            params.append('multiple', 'sucesso');
            params.append('list_code', list_code);
            this.$axios.$post('/?p=ecommerce_pedidos_produtos_api_v1', params).then((response) => {
                this.FacebookPixelAddToCart(product.price, product.code, product.name, product.qty)
                if (response.status === 'success') {
                    this.$store.dispatch('order/loadOrder')
                    this.$nuxt.$emit('openMiniCart')
                        let item = []
                        item.push({
                            id: product.id,
                            quantity: product.qty,
                            name: product.name,
                            price: product.price,
                            brand: product.brand.name || "Sem marca"
                        });
                        this.gtagEvent('event', 'add_to_cart', {currency: "BRL", value: product.price * qty || 1, items: item})
                } else {
                    alert(response.message)
                }
            })
            .catch(function (error) {
                console.error(error);
            })
        },
        deleteProduct(product) {
            if (!confirm('Você tem certeza que quer remover esse item?')) {
                return;
            }
            this.product_loading = true
            this.$axios.$delete(`/?p=ecommerce_pedidos_produtos_api_v1&id=${product.id}`)
                .then((response) => {
                    if (response.status === 'success') {
                        this.$store.dispatch('order/loadOrder', () => {
                            this.$nuxt.$emit('updated_cart')
                            this.product_loading = false
                        })
                    } else {
                        throw 'Não foi possível remover o produto';
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    alert(error);
                })
        },
        saveProductQty(product) {
            this.product_loading = true
            this.$axios.$put(`/?p=ecommerce_pedidos_produtos_api_v1&id=${product.id}`, {qty: product.qty})
                .then((response) => {
                    if (response.status === 'success') {
                        this.$store.dispatch('order/loadOrder', () => {
                            this.$nuxt.$emit('updated_cart')
                            this.product_loading = false
                        })
                    } else {
                        let msg = 'Não foi possível alterar o produto';
                        if (response.message) {
                            msg += `: ${response.message}`
                        }
                        alert(msg)
                        this.product_loading = false
                        this.qty = parseFloat(product.quantidade).toFixed(this.product.unidade_precisao || 0)
                        console.log(response)
                    }
                })
                .catch((error) => {
                    this.product_loading = false
                    console.error(error);
                    alert(error);
                })
        },
        changeQtyInCart(product, op) {
            if (this.product_loading) {
                return false;
            }
            let precision = 0
            if ('unidade_precisao' in product) {
                precision = product.unidade_precisao
            }
            let multiplicador = 1;
            if (product.multiplicador) {
                multiplicador = parseFloat(product.multiplicador)
            }
            if (op === null && !isNaN(product.quantidade) && product.quantidade > 0) {
                this.saveProductQty(product);
                return;
            }
            if (op === '-' && !isNaN(product.quantidade) && product.quantidade > multiplicador) {
                let qty = (parseFloat(product.quantidade) - multiplicador).toFixed(precision)
                product = Object.assign({}, product, {qty: qty})
                this.saveProductQty(product);
                return;
            }
            if (op === '+' && !isNaN(product.quantidade)) {
                let qty = (parseFloat(product.quantidade) + multiplicador).toFixed(precision)
                product = Object.assign({}, product, {qty: qty})
                this.saveProductQty(product);
                return;
            }
            alert('Quantidade inválida');
        },
        validQtyInCart(product, qty) {
            let precision = 0
            if ('unidade_precisao' in product) {
                precision = product.unidade_precisao
            }
            let multiplicador = 1;
            if (product.multiplicador) {
                multiplicador = parseFloat(product.multiplicador)
            }
            if (qty % multiplicador > 0) {
                alert(`Quantidade inválida. A quantidade deve ser um múltiplo de ${multiplicador}.`);
                return false
            }
            product = Object.assign({}, product, {qty: qty})
            this.saveProductQty(product);
        }
    }
}
